
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import Head from "./components/model/Model";
import './App.css';



function App() {

  const [mouseX, setMouseX] = useState("");
  const [mouseY, setMouseY] = useState("");

  const isFrontpage = useLocation().pathname === '/';


  return (
    <div onMouseMove={isFrontpage ? (e) => { setMouseX(e.clientX); setMouseY(e.clientY) } : undefined}>
      <div className="container">
        <h1 className="header primary-text">Faruk Shuaibu Adamu</h1>
        <p className="intro sub-text">I am a product designer & engineer currently building smart logistics infrastructure for
          Africa’s commerce.</p>
        <p className="link-1-container link-1 sub-text">
          {/* <a href="/about">about me</a> */}
          {/* <a href="/resume">my resume<svg className="arrow" width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3879 9.91834L6.31779 15.957L0.24768 9.91834C0.176062 9.8638 0.116998 9.79451 0.0744857 9.71516C0.0319738 9.63581 0.00700698 9.54826 0.00127515 9.45842C-0.00445668 9.36858 0.00918069 9.27856 0.0412636 9.19446C0.0733465 9.11035 0.123126 9.03412 0.187232 8.97092C0.251339 8.90772 0.328275 8.85904 0.412833 8.82816C0.49739 8.79728 0.587595 8.78493 0.677341 8.79194C0.767086 8.79896 0.854277 8.82517 0.933011 8.86882C1.01174 8.91246 1.08018 8.9725 1.13369 9.04489L5.68942 13.5629L5.68942 1.54208C5.68942 1.37543 5.75562 1.2156 5.87346 1.09776C5.99131 0.979912 6.15114 0.913707 6.31779 0.913707C6.48445 0.913707 6.64428 0.979912 6.76212 1.09776C6.87997 1.2156 6.94617 1.37543 6.94617 1.54208L6.94617 13.5629L11.5019 9.04489C11.6202 8.9274 11.7804 8.86173 11.9471 8.86232C12.1139 8.86291 12.2736 8.92971 12.391 9.04804C12.5085 9.16636 12.5742 9.32651 12.5736 9.49326C12.573 9.66001 12.5062 9.8197 12.3879 9.93719V9.91834Z" fill="#FCFDFF" />
          </svg>
          </a> */}
          <a href="https://read.cv/rook" target="_blank" rel="noreferrer">my resume<svg className="arrow" width="12" height="11" viewBox="0 0 12 11" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.60643 0.0166252L11.167 0.18827L11.0397 8.74957C11.0503 8.83898 11.0414 8.9296 11.0139 9.0153C10.9863 9.101 10.9407 9.17978 10.8801 9.24631C10.8194 9.31284 10.7452 9.36556 10.6624 9.40091C10.5796 9.43625 10.4902 9.4534 10.4002 9.45119C10.3102 9.44897 10.2218 9.42745 10.1408 9.38808C10.0599 9.3487 9.98831 9.29239 9.93101 9.22296C9.87371 9.15353 9.832 9.0726 9.8087 8.98565C9.7854 8.8987 9.78106 8.80775 9.79596 8.71898L9.88128 2.30338L1.23422 10.6538C1.11433 10.7695 0.953374 10.8329 0.786744 10.83C0.620114 10.8271 0.461463 10.7581 0.345694 10.6382C0.229925 10.5184 0.166521 10.3574 0.16943 10.1908C0.172338 10.0241 0.241321 9.86549 0.361203 9.74972L9.00827 1.39934L2.5936 1.26071C2.42689 1.25721 2.26839 1.18763 2.15298 1.06727C2.03757 0.946914 1.9747 0.78564 1.9782 0.618927C1.9817 0.452214 2.05128 0.29372 2.17164 0.17831C2.292 0.062901 2.45328 3.08766e-05 2.61999 0.00353016L2.60643 0.0166252Z"
              fill="#c8c8c8" />
          </svg>
          </a>
        </p>

        <div className="link-2-container link-2 sub-text">
          <p className="sub-text2">my projects</p>
          {/* <a className="dako" href="https://chestr.com" target="_blank">Chestr <span className="sub-text">/ Ongoing</span></a>
          <a className="dako" href="https://kera.health" target="_blank">Kera <span className="sub-text">/ Ongoing</span></a> */}
          <a className="dako" href="https://thedelivery.company" target="_blank" rel="noreferrer">The Delivery Company <span className="sub-text">/ Ongoing</span></a>
          <a className="chestr" href="https://chestr.app" target="_blank" rel="noreferrer">Chestr <span className="sub-text">/ 2022</span></a>
          {/* <a className="yodaBox" href="https://portfolio.rook.ninja/yodabox" target="_blank" rel="noreferrer">Yodabox <span className="sub-text">/ 2020</span></a> */}
          {/* <span className="weirdLocus" href="/about">Weird Locus <span className="sub-text">/ Ongoing / Workng on it</span></span>
          <span className="experiments" href="/about">Experiments & Hacks (0) <span className="sub-text">/ Ongoing / Workng on it</span></span> */}
        </div>
        <div className="link-3-container link-3 sub-text">
          <a href="https://www.linkedin.com/in/farukshuaibu" target="_blank" rel="noreferrer">linked In<svg className="arrow" width="12" height="11" viewBox="0 0 12 11" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.60643 0.0166252L11.167 0.18827L11.0397 8.74957C11.0503 8.83898 11.0414 8.9296 11.0139 9.0153C10.9863 9.101 10.9407 9.17978 10.8801 9.24631C10.8194 9.31284 10.7452 9.36556 10.6624 9.40091C10.5796 9.43625 10.4902 9.4534 10.4002 9.45119C10.3102 9.44897 10.2218 9.42745 10.1408 9.38808C10.0599 9.3487 9.98831 9.29239 9.93101 9.22296C9.87371 9.15353 9.832 9.0726 9.8087 8.98565C9.7854 8.8987 9.78106 8.80775 9.79596 8.71898L9.88128 2.30338L1.23422 10.6538C1.11433 10.7695 0.953374 10.8329 0.786744 10.83C0.620114 10.8271 0.461463 10.7581 0.345694 10.6382C0.229925 10.5184 0.166521 10.3574 0.16943 10.1908C0.172338 10.0241 0.241321 9.86549 0.361203 9.74972L9.00827 1.39934L2.5936 1.26071C2.42689 1.25721 2.26839 1.18763 2.15298 1.06727C2.03757 0.946914 1.9747 0.78564 1.9782 0.618927C1.9817 0.452214 2.05128 0.29372 2.17164 0.17831C2.292 0.062901 2.45328 3.08766e-05 2.61999 0.00353016L2.60643 0.0166252Z"
              fill="#c8c8c8" />
          </svg>
          </a>
          <a href="https://www.twitter.com/726f6f6b" target="_blank" rel="noreferrer">twitter<svg className="arrow" width="12" height="11" viewBox="0 0 12 11" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.60643 0.0166252L11.167 0.18827L11.0397 8.74957C11.0503 8.83898 11.0414 8.9296 11.0139 9.0153C10.9863 9.101 10.9407 9.17978 10.8801 9.24631C10.8194 9.31284 10.7452 9.36556 10.6624 9.40091C10.5796 9.43625 10.4902 9.4534 10.4002 9.45119C10.3102 9.44897 10.2218 9.42745 10.1408 9.38808C10.0599 9.3487 9.98831 9.29239 9.93101 9.22296C9.87371 9.15353 9.832 9.0726 9.8087 8.98565C9.7854 8.8987 9.78106 8.80775 9.79596 8.71898L9.88128 2.30338L1.23422 10.6538C1.11433 10.7695 0.953374 10.8329 0.786744 10.83C0.620114 10.8271 0.461463 10.7581 0.345694 10.6382C0.229925 10.5184 0.166521 10.3574 0.16943 10.1908C0.172338 10.0241 0.241321 9.86549 0.361203 9.74972L9.00827 1.39934L2.5936 1.26071C2.42689 1.25721 2.26839 1.18763 2.15298 1.06727C2.03757 0.946914 1.9747 0.78564 1.9782 0.618927C1.9817 0.452214 2.05128 0.29372 2.17164 0.17831C2.292 0.062901 2.45328 3.08766e-05 2.61999 0.00353016L2.60643 0.0166252Z"
              fill="#c8c8c8" />
          </svg>
          </a>
          <a href="https://github.com/726f6f6b" target="_blank" rel="noreferrer">github<svg className="arrow" width="12" height="11" viewBox="0 0 12 11" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.60643 0.0166252L11.167 0.18827L11.0397 8.74957C11.0503 8.83898 11.0414 8.9296 11.0139 9.0153C10.9863 9.101 10.9407 9.17978 10.8801 9.24631C10.8194 9.31284 10.7452 9.36556 10.6624 9.40091C10.5796 9.43625 10.4902 9.4534 10.4002 9.45119C10.3102 9.44897 10.2218 9.42745 10.1408 9.38808C10.0599 9.3487 9.98831 9.29239 9.93101 9.22296C9.87371 9.15353 9.832 9.0726 9.8087 8.98565C9.7854 8.8987 9.78106 8.80775 9.79596 8.71898L9.88128 2.30338L1.23422 10.6538C1.11433 10.7695 0.953374 10.8329 0.786744 10.83C0.620114 10.8271 0.461463 10.7581 0.345694 10.6382C0.229925 10.5184 0.166521 10.3574 0.16943 10.1908C0.172338 10.0241 0.241321 9.86549 0.361203 9.74972L9.00827 1.39934L2.5936 1.26071C2.42689 1.25721 2.26839 1.18763 2.15298 1.06727C2.03757 0.946914 1.9747 0.78564 1.9782 0.618927C1.9817 0.452214 2.05128 0.29372 2.17164 0.17831C2.292 0.062901 2.45328 3.08766e-05 2.61999 0.00353016L2.60643 0.0166252Z"
              fill="#c8c8c8" />
          </svg>
          </a>
        </div>
        <p className="sub-text footer">2024 © function, form, & aesthetics.</p>
      </div>
      <div id="canvas-container">
        <Head mouseX={mouseX} mouseY={mouseY} />
      </div>
    </div>
  );
}

export default App;
